<template>
  <div>
    <div class="document-item">
      <h2><span class="h2-weight">1、引入SDK</span></h2>
      <p>
        intellijocean-pay-client-sdk是聚合支付的核心SDK模块，“无脑”引入intellijocean-pay-client-sdk模块是快速开始和深入应用的最佳方式。</p>
      <PrismEditorCode :code="code" :demo="demo"></PrismEditorCode>
    </div>

    <div class="document-item">
      <h2><span class="h2-weight">2、配置YAML文件</span></h2>
      <p>只需简单配置，即可快速接入聚合支付</p>
      <PrismEditorCode :code="code2" :demo="demo"></PrismEditorCode>
    </div>

    <div class="document-item">
      <h2><span class="h2-weight">3、接入参数获取位置指引</span></h2>
      <h3>1、获取聚合支付平台商户ID</h3>
      <el-image class="image" :src="require('@/assets/images/guide-1.png')"
        :preview-src-list="[require('@/assets/images/guide-1.png')]">
      </el-image>
      <h3>2、获取商户应用ID</h3>
      <el-image class="image" :src="require('@/assets/images/guide-2.png')"
        :preview-src-list="[require('@/assets/images/guide-2.png')]">
      </el-image>
      <h3>3、获取商户应用密钥</h3>
      <el-image class="image" :src="require('@/assets/images/guide-3.png')"
        :preview-src-list="[require('@/assets/images/guide-3.png')]">
      </el-image>
      <h3>4、配置第三方支付渠道参数信息</h3>
      <el-image class="image" :src="require('@/assets/images/guide-4.png')"
        :preview-src-list="[require('@/assets/images/guide-4.png')]">
      </el-image>
      <h3>5、启用指定渠道的支付方式</h3>
      <el-image class="image" :src="require('@/assets/images/guide-5.png')"
        :preview-src-list="[require('@/assets/images/guide-5.png')]">
      </el-image>
      <h3>6、在线测试</h3>
      <el-image class="image" :src="require('@/assets/images/guide-6.png')"
        :preview-src-list="[require('@/assets/images/guide-6.png')]">
      </el-image>
    </div>
  </div>
</template>

<script>
import PrismEditorCode from '@/components/PrismEditorCode/index.vue'

export default {
  name: 'accessGuide',
  components: {
    PrismEditorCode,
  },

  data() {
    return {
      code:
        `   <dependencies>
        <dependency>
            <groupId>com.intellij.ocean</groupId>
            <artifactId>intellijocean-pay-client-sdk</artifactId>
            <version>0.0.1</version>
        </dependency>
   </dependencies>`,
      code2: `  intellijocean:
     pay:
         # 平台商户号
         mch-no: 1732700580097421314
         #  商户的应用ID，
         mch-app-id: 1767373593627955202
         #  商户的应用私钥
         app-Secret: $2a$10$jeQ24ioxfQKI1jqwedFxy.qweq.RQFEADWFoQD9vBwFytBR9uyWEQ`,
      demo: `<dependency>`
    };
  },

  beforeCreate() {
    console.log("父组件：beforeCreate", this.demo)
  },

  created() {
    console.log("父组件：created", this.demo)
  },

  beforeMount() {
    console.log("父组件：beforeMount", this.demo)
  },

  mounted() {
    console.log("父组件：mounted", this.demo)
  },

  methods: {},
}
</script>

<style scoped lang="less">
// 每一块文档
.document-item {
  margin-bottom: 50px;

  .h2-weight {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-family: Hiragino Sans GB;
  }

  .image {
    width: 40%;
  }
}
</style>